import React, { useEffect, useState } from "react"
import IconStarFill from "./icon/StarFill"
import IconStarFull from "./icon/StarFull"

type RatingProperties = {
  ratings: number
  selectRating: (rating: number) => void
}

const Rating: React.FC<RatingProperties> = ({ ratings, selectRating }) => {
  const [rating, setRating] = useState(0)

  const handleClick = (index: any) => {
    setRating(index + 1)
  }

  useEffect(() => {
    if (rating !== 0) {
      selectRating(rating)
    }
  }, [rating])

  return (
    <div className="star-ratings">
      {[...Array(5)].map((_, index) => (
        <span
          key={index}
          onClick={() => handleClick(index)}
          id={`star-${index + 1}`}
          style={{ paddingRight: `10px`, cursor: `pointer` }}
        >
          {index < rating ? <IconStarFull /> : <IconStarFill />}
        </span>
      ))}
    </div>
  )
}

export default Rating
