import React from "react";

const IconStarFill: React.FC = () => (
  <svg
    width="33"
    height="31"
    viewBox="0 0 33 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3057 1.08156C16.4554 0.620906 17.1071 0.6209 17.2568 1.08156L20.354 10.6138C20.5548 11.2318 21.1307 11.6503 21.7806 11.6503H31.8034C32.2877 11.6503 32.4891 12.2701 32.0973 12.5548L23.9886 18.446C23.4629 18.828 23.2429 19.505 23.4437 20.1231L26.541 29.6553C26.6906 30.116 26.1634 30.499 25.7715 30.2143L17.6629 24.3231C17.1372 23.9411 16.4253 23.9411 15.8996 24.3231L7.79097 30.2143C7.39911 30.499 6.87187 30.116 7.02155 29.6553L10.1188 20.1231C10.3196 19.505 10.0996 18.828 9.57385 18.446L1.46524 12.5548C1.07339 12.2701 1.27477 11.6503 1.75914 11.6503H11.7819C12.4318 11.6503 13.0077 11.2318 13.2085 10.6138L16.3057 1.08156Z"
      stroke="#005059"
    />
  </svg>
);

export default IconStarFill;
