import { useToast } from "@chakra-ui/react"
import { isEmpty } from "lodash"
import React, { useState } from "react"
import { QuestTionVocCao } from "../../../data"

type QuestionProps = {
  rating?: any
  children: React.ReactNode
  submitForm: (data: any) => void
}

type QuestionListValue = {
  question: string
  answer: string
  title: string
}

export default function QuestionVoc({ rating, submitForm }: QuestionProps) {
  const [dataSelect, setDataSelect] = useState<any[]>([])

  const toast = useToast()

  const onChangeOtherFeedback = (value: string) => {
    const updatedQuestions = { ...dataSelect }
    updatedQuestions[`y_kien_khac` as any] = value
    setDataSelect(updatedQuestions)
  }

  const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    })
  }

  const handleSelectAnswer = (select: QuestionListValue) => {
    const isAlreadySelected = Object.keys(dataSelect).includes(select.answer)
    if (isAlreadySelected) {
      const newDataSelect = { ...dataSelect }
      delete newDataSelect[select.answer as any]
      setDataSelect(newDataSelect)
    } else {
      setDataSelect((prevDataSelect) => ({
        ...prevDataSelect,
        [select.answer]: select.title,
      }))
    }
  }

  const onSubmitForm = () => {
    if (isEmpty(dataSelect) || isEmpty(dataSelect)) {
      showErrorMsg(`Vui lòng chọn lý do phản hổi!`)
    } else {
      submitForm({
        feedback: dataSelect,
      })
    }
  }

  return (
    <div className="container-c">
      {rating >= 4 ? (
        <h3>
          Vui lòng cho CAO biết điều gì đã&nbsp;
          <br className="brLine" />
          mang đến&nbsp; <br className="brLine-new" /> cho Quý khách trải nghiệm hài lòng?
        </h3>
      ) : (
        <h3>CAO có thể làm gì để cải thiện trải nghiệm&nbsp; <br className="brLine-new" /> của Quý Khách?</h3>
      )}
      <div className="question-select">
        {QuestTionVocCao.map((item: any) => {
          const isActive = Object.entries(dataSelect).some(([key, value]) => key === item.answer)
          return (
            <div
              key={item.answer}
              className={`question-select-item${isActive ? `-active` : ``}`}
              onClick={() => {
                handleSelectAnswer(item)
              }}
            >
              {item.title}
            </div>
          )
        })}
      </div>
      <p className="comment-label">
        Quý khách có thể chia sẻ chi tiết hơn không?&nbsp;
        <br className="brLine brLine-new" />
        (không bắt buộc)
      </p>
      <textarea
        className="comment-cao"
        onChange={(e) => onChangeOtherFeedback(e.target.value)}
        name="description"
        id="description"
        cols={20}
        rows={10}
        style={{ width: `100%` }}
        placeholder="Write here"
      ></textarea>
      <button onClick={onSubmitForm} className="submit-voc">
        Gửi phản hồi
      </button>
    </div>
  )
}
