import React, { useEffect } from "react";

type ThankForSubmitProps = {
  isGood: boolean
  children?: React.ReactNode
}

export default function ThankForSubmit({ isGood }: ThankForSubmitProps) {

  useEffect(() => {
    const setVhUnit = () => {
      // eslint-disable-next-line prefer-const
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(`--vh`, `${vh}px`);
    };

    // Set the vh unit initially
    setVhUnit();

    // Adjust the vh unit on orientation change
    window.addEventListener(`resize`, setVhUnit);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener(`resize`, setVhUnit);
    };
  }, []);
  return (
    <div className="body-caf-thank" style={{ height: `calc(var(--vh, 1vh) * 100)` }}>
      <img src="/images/Logo-Caf-01.png" alt="logo" width={200} />
      {isGood === true ? (
        <div className="content">
          <h1 style={{ color: `black` }}>
            Xin chân thành cảm ơn Quý khách&nbsp;
            <br className="brLine brLine-new" />
            vì đã dành thời gian quý báu để chia sẻ&nbsp;<br className="brLine-new" />và phản hồi
          </h1>
          <p style={{ color: `black` }}>
            Tất cả những đóng góp từ Quý khách hàng&nbsp;
            <br className="brLine-new" />
            chính là nguồn động lực to lớn
            để&nbsp;<br className="brLine" />CAO Fine Jewellery&nbsp;
            <br className="brLine-new" />
            ngày càng hoàn thiện và mang đến những sản phẩm,&nbsp;
            <br className="brLine-new" />
            dịch vụ tốt nhất.
          </p>
        </div>
      ) : (
        <div className="content">
          <h1 style={{ color: `black` }}>
          CAO Fine Jewellery chân thành xin lỗi Quý khách&nbsp;
            <br className="brLine-new brLine" />
            về những trải nghiệm không như mong đợi.
          </h1>
          <p style={{ color: `black` }}>
          CAO luôn trân trọng sự cảm thông&nbsp;
            {/* <br className="brLine-new brLine" /> */}
            và mong được tiếp tục&nbsp;<br className="brLine" />phục vụ Quý khách&nbsp;
            {/* <br className="brLine-new" /> */}
            với chất lượng dịch vụ ngày càng tốt hơn.
          </p>
        </div>
      )}
    </div>
    // <div className="container-c">
    //   <img
    //     src={`/images/voice/${isGood ? `success-feedback.png` : `failed-feedback.png`}`}
    //     alt="feedback"
    //     width={`${isGood ? 227 : 149}`}
    //     style={{ marginBottom: `45px` }}
    //   />
    //   {isGood === true ? (
    //     <>
    //       <h4 className="h4-cao">Xin chân thành cảm ơn Quý Khách đã dành thời gian quý báu để chia sẻ và phản hồi</h4>
    //       <p className="body1-cao">
    //         Tất cả những đóng góp từ Quý Khách chính là nguồn động lực to lớn để CAO Fine&nbsp;
    //         <br className="brLine" />
    //         Jewellery ngày càng hoàn thiện và mang đến những sản phẩm, dịch vụ tốt hơn
    //       </p>
    //     </>
    //   ) : (
    //     <>
    //       <h4 className="h4-cao">
    //         CAO Fine Jewellery rất tiếc vì trải nghiệm chưa tốt vừa qua, kính mong Quý Khách thông cảm và tiếp tục đồng
    //         hành cùng CAO Fine Jewellery trong thời gian tới.
    //       </h4>
    //     </>
    //   )}
    // </div>
  )
}
