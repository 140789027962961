import React from "react";

const NotFoundOrderCao: React.FC = () => {
  const link = document.location.href;
  return (
    <div className="not-found">
      <img src="/images/Logo-caf-1.png" alt="caf" />
      <h1>
        Không tìm thấy đơn hàng&nbsp;
        <br />
        Quý khách vui lòng thử lại !
      </h1>
      <a href={link} className="submit-voc">
        TẢI LẠI
      </a>
    </div>
  );
};

export default NotFoundOrderCao;
