import { useToast } from "@chakra-ui/react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NotFoundOrderCao from "../components/404/index-cao";
import ThankForSubmit from "../components/cao/Thanks";
import ThankYouVocCAF from "../components/cao/Thankyou";
import Rating from "../components/cao/voice-question/index-voice";
import QuestionVoc from "../components/cao/voice-question/question";
import { save, validate } from "../repositories/feedback_repository";
import { useStyles } from "../style";

enum Step {
  StepWellCome,
  StepFeedback,
  StepThankYou,
}

type DataQuestion = {
  feedback: any;
};

interface VoiceOfCustomerProperties {
  soNum: string;
  phoneNum: string;
  company: string;
}

const IndexPage2: React.FC<VoiceOfCustomerProperties> = ({ soNum, phoneNum, company }: any) => {
  const [step, setStep] = useState<Step | null>(null);
  const [ratings, setRatings] = useState<number>(0);
  const [isNotFount, setIsNotFount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const toast = useToast();

  const showErrorMsg = async (msg: string): Promise<void> => {
    toast({
      title: msg || ``,
      status: `error`,
      duration: 2000,
      isClosable: false,
    });
  };

  const selectRating = async (value: number): Promise<void> => {
    setRatings(value);
    setIsLoading(true);
    setTimeout(() => {
      setStep(Step.StepFeedback);
      setIsLoading(false);
    }, 1000);
  };

  const validateSONumber = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);
      const response = await validate(soNum, phoneNum, company);
      if (response.statusCode === 200) {
        setIsLoading(false);
        setStep(Step.StepWellCome);
        setIsNotFount(false);
      } else {
        setIsNotFount(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setIsNotFount(true);
    }
  };

  useEffect(() => {
    validateSONumber();
  }, []);

  const isRating = ratings > 3;

  const handleSubmitData = async (data: DataQuestion) => {
    setIsLoading(true);
    const response = await save(soNum, phoneNum, data?.feedback, ratings);
    if (response.statusCode === 200) {
      setIsLoading(true);
      setTimeout(() => {
        setStep(Step.StepThankYou);
        setIsLoading(false);
      }, 1000);
    } else {
      setIsLoading(false);
      showErrorMsg(`Lỗi hệ thống`);
    }
  };

  useEffect(() => {
    const setVhUnit = () => {
      // eslint-disable-next-line prefer-const
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty(`--vh`, `${vh}px`);
    };

    // Set the vh unit initially
    setVhUnit();

    // Adjust the vh unit on orientation change
    window.addEventListener(`resize`, setVhUnit);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener(`resize`, setVhUnit);
    };
  }, []);

  return (
    <>
      <Helmet async>
        <link rel="icon" href="/images/cao.ico" />
        <link rel="shortcut icon" href="/images/cao.ico" />
        <link rel="apple-touch-icon" href="/images/cao.ico" />
        <title>caofinejewellery</title>
      </Helmet>
      <main>
        {isNotFount && <NotFoundOrderCao />}
        {isLoading && (
          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
        {step === Step.StepWellCome && !isNotFount && (
          <div className="body-caf" style={{ height: `calc(var(--vh, 1vh) * 100)` }}>
            <ThankYouVocCAF isFeedback={false} />
            <Rating
              selectRating={(number) => {
                selectRating(number);
              }}
              ratings={ratings}
            />
            <div className="thank-you">THANK YOU</div>
          </div>
        )}
        {step === Step.StepFeedback && !isNotFount && (
          <div className="body-caf-feed-back" style={{ height: `calc(var(--vh, 1vh) * 100)` }}>
            <ThankYouVocCAF isFeedback={step === Step.StepFeedback} />
            <QuestionVoc children rating={ratings} submitForm={handleSubmitData} />
          </div>
        )}
        {step === Step.StepThankYou && !isNotFount && <ThankForSubmit isGood={isRating} />}

        {/* <img src="/images/background-footer.png" alt="ssss" /> */}
        {/* {isNotFount && <NotFoundOrderCao />}
          {isLoading && (
            <Backdrop className={classes.backdrop} open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {step === Step.StepWellCome && !isNotFount && (
            <div>
              <ThankYouVocCAF />
              <VoiceOfCustomer rating={rating} selectRating={selectRating} />
            </div>
          )}
          {step === Step.StepFeedback && !isNotFount && (
            <>
              <VoiceOfCustomer rating={rating} selectRating={selectRating} />
            <QuestionVoc
                children
                rating={rating}
                submitForm={handleSubmitData}
              />
            </>
          )}
          {step === Step.StepThankYou && !isNotFount && (
            <ThankForSubmit isGood={isRating} />
          )} */}
      </main>
    </>
  );
};

export default IndexPage2;
