import React from "react"

type ThanhYouProperties = {
  isFeedback?: boolean
}



const ThankYouVocCAF: React.FC<ThanhYouProperties> = ({ isFeedback }) => {
  
  return (
    <>
      <div className="content-wellcome">
        <div className="logo">
          <img src="/images/Logo-Caf-01.png" alt="caf" />
        </div>
        <div className={isFeedback === true ? `content-thank` : `content-thank first-well`}>
          <h1>
            Cảm ơn Quý khách đã tin tưởng&nbsp;
            <br className="brLine brLine-new" />
            và lựa chọn sản phẩm trang sức cao cấp &nbsp;
            <br className="brLine brLine-new" />
            tại CAO Fine Jewellery
          </h1>
          {isFeedback === false && (
            <p>
              Kính mời Quý khách đánh giá mức độ hài lòng&nbsp;
              <br className="brLine brLine-new" />
              về trải nghiệm mua hàng tại CAO
            </p>
          )}
        </div>
      </div>
    </>
  )
}

export default ThankYouVocCAF
