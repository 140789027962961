import React from "react";

const IconStarFull: React.FC = () => (
  <svg
    width="33"
    height="31"
    viewBox="0 0 33 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.6427 0.927048C15.942 0.00573707 17.2455 0.00574088 17.5448 0.927052L20.642 10.4593C20.7759 10.8713 21.1598 11.1503 21.5931 11.1503H31.6159C32.5846 11.1503 32.9874 12.3899 32.2036 12.9593L24.095 18.8505C23.7446 19.1052 23.5979 19.5565 23.7318 19.9686L26.829 29.5008C27.1283 30.4221 26.0739 31.1882 25.2901 30.6188L17.1815 24.7276C16.831 24.4729 16.3565 24.4729 16.006 24.7276L7.89736 30.6188C7.11364 31.1882 6.05917 30.4221 6.35852 29.5008L9.45573 19.9686C9.5896 19.5565 9.44295 19.1052 9.09246 18.8505L0.983851 12.9593C0.200137 12.3899 0.602916 11.1503 1.57164 11.1503H11.5944C12.0277 11.1503 12.4116 10.8713 12.5455 10.4593L15.6427 0.927048Z"
      fill="#005059"
      fill-opacity="0.972549"
    />
  </svg>
);

export default IconStarFull;
